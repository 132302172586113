<template>
    <v-main>
        <v-container>
            <h2>Space Occupancy</h2>
        </v-container>
    </v-main>
</template>
<script>
    export default {
        name: 'Space',
        components: {},
        setup() {
            return {};
        }
    }
</script>